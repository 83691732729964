import { createTheme, Palette, PaletteOptions } from "@mui/material/styles";
import { TypographyOptions } from "@mui/material/styles/createTypography";

// TODO: Optimize font import

declare module '@mui/material/styles' {
  interface Theme {
    palette: {
      primary: {
        main: string;
      };
    };
    typography: {
      fontFamily: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    palette?: PaletteOptions;
    typography?: TypographyOptions | ((palette: Palette) => TypographyOptions);
  }
}
const theme = createTheme({
  palette: {
    primary: {
      main: "#cd0000",
    },
  },
  typography: {
    fontFamily: "Noto Sans",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: "Noto Sans";
          src: url("./font/notosans-light.woff2") format("woff2"),
            url("./font/notosans-light.woff") format("woff");
          font-weight: 300;
          font-style: normal;
        }

        @font-face {
          font-family: "Noto Sans";
          src: url("./font/notosans-regular.woff2") format("woff2"),
            url("./font/notosans-regular.woff") format("woff");
            font-weight: normal;
          font-style: normal;
        }

        @font-face {
          font-family: "Noto Sans";
          src: url("./font/notosans-semibold.woff2") format("woff2"),
            url("./font/notosans-semibold.woff") format("woff");
            font-weight: 600;
          font-style: normal;
        }

        @font-face {
          font-family: "Noto Sans";
          src: url("./font/notosans-bold.woff2") format("woff2"),
            url("./font/notosans-bold.woff") format("woff");
            font-weight: 700;
          font-style: normal;
        }
      `
    }
  }
});

export default theme;
