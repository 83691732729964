const setupWindowInnerHeightTracker = () => {
  const setHeightVar = () =>
    document.documentElement.style.setProperty(
      "--vh",
      `${window.innerHeight}px`
    );

  setHeightVar();
  window.onresize = () => setHeightVar();
};

export const preAppLoadHooks = () => {
  setupWindowInnerHeightTracker();
};

export const postAppLoadHooks = () => {};
